<template>
  <CRow>
    <CCol cols="12" lg="12" md="12">
      <CCard class="todo_lists d-block mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              {{ $t('todo.Todo_lists') }}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showCompleted" size="is-small" @input="getTodoLists()">{{ $t('todo.show_completed') }}</b-switch>
              <!-- <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="searchEnabled" size="is-small">{{ $t('common.activate_search') }}</b-switch> -->
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-2 link" color="primary" @click="todoListModal = true; descriptionCollapsed = true; resetTodoListData(); pauseGetStarted();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('todo.Add_todo_list') }}</span>
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0' : overviewDataLoaded}">
              <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('common.Loading')"/>
              <div v-else>
                <div class="multiselect_container">
                  <label>{{ $t('common.filter_by_category') }}</label>
                  <multiselect
                    class="data_table"
                    v-model="filter.categories"
                    :options="categories" 
                    :multiple="true"
                    :group-select="false"
                    :placeholder="$t('common.filter_by_category')"
                    :hideSelected="true"
                    :closeOnSelect="false"
                    :selectLabel="$t('common.Add_category')"
                    :selectedLabel="$t('Added')"
                    :deselectLabel="$t('common.Remove_category')"
                    track-by="todo_list_category_name" 
                    label="todo_list_category_name"
                    @input="updateTodoListFilter();">
                    <span slot="noResult">{{ $t('Nothing_found') }}</span>
                  </multiselect>
                </div>                          
                <b-table ref="todoListTable" class="data_table" :data="todoLists" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                  <template slot-scope="todo">
                    <b-table-column field="image" width="2.5%">
                      <div v-if="todo.row.todo_list_template_id !== null && todo.row.image !== null" class="todo_list_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/hub-todo/' + todo.row.todo_list_template_id_external + '/' + todo.row.image + '/' + clientToken + ')' }"></div>
                      <div v-else-if="todo.row.todo_list_template_id === null && todo.row.image !== null" class="todo_list_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/hub-todo/' + todo.row.todo_list_id_external + '/' + todo.row.image + '/' + clientToken + ')' }"></div>
                      <div v-else class="todo_list_icon d-inline-block align-middle mr-2" v-bind:class="{'onboarding' : todo.row.todo_list_type_tag === 'onboarding'}">         
                        <i class="fas" v-bind:class="{'fa-hands-helping' : todo.row.todo_list_type_tag === 'onboarding', 'fa-clipboard-list' : todo.row.todo_list_type_tag !== 'onboarding'}"/>
                      </div>
                    </b-table-column>
                    <b-table-column field="name" :label="$t('todo.Todo_list')" :searchable="searchEnabled">
                      <quick-edit type="text" mode="ignore" :buttonOkText="$t('ok')" :buttonCancelText="$t('cancel')" v-model="todo.row.name" v-on:input="updateTodoList(todo.row.todo_list_id_external, todo.row)">
                        <span>{{todo.row.name}}<i class="icon edit fas fa-pen ml-1"/></span>
                      </quick-edit>                    
                    </b-table-column>
                    <b-table-column field="todo_list_category_name" :label="$t('Category')" :searchable="searchEnabled" width="20%">
                      <quick-edit type="select" :options="categories" mode="ignore" :buttonOkText="$t('ok')" :buttonCancelText="$t('cancel')" v-model="todo.row.todo_list_category_id" v-on:input="updateTodoList(todo.row.todo_list_id_external, todo.row)">
                        <span>{{todo.row.todo_list_category_name}}<i class="icon edit fas fa-pen ml-1"/></span>
                      </quick-edit>
                    </b-table-column>
                    <b-table-column field="nr_of_items" :label="$t('todo.No_of_items')" width="10%">
                      <span>{{todo.row.nr_of_items > 0 ? todo.row.nr_of_items + ' ' + $t('todo.items') : '-'}}</span>
                    </b-table-column>
                    <b-table-column field="created_by" :label="$t('todo.Created_by')" :searchable="searchEnabled" width="10%">
                      <span>{{todo.row.created_by}}</span>
                    </b-table-column>
                    <b-table-column field="created_at" :label="$t('todo.Created')" width="10%">  
                      <span>{{todo.row.created | moment("DD-MM-YYYY HH:mm")}}</span>
                    </b-table-column>
                    <b-table-column field="completed_at" :visible="showCompleted" :label="$t('todo.Completed')" width="10%">
                      <span v-if="todo.row.is_completed === true">{{todo.row.completed | moment("DD-MM-YYYY HH:mm")}}</span>
                      <span v-else>-</span>
                    </b-table-column>        
                    <b-table-column field="actions">
                      <div class="d-flex justify-content-lg-end">
                        <CDropdown color="primary" toggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                          <div slot="toggler-content">
                            <span class="d-flex align-items-center">
                              {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                            </span>
                          </div>

                          <CDropdownItem>
                            <div class="d-flex align-items-center" @click="todoListModal = true; todoListModalData = todo.row; descriptionCollapsed = true; activeStep = 0;">
                              <div class="post_action_icon text-center mr-1">
                                <i class="fas fa-pen"/>
                              </div>
                              <div class="flex-grow-1">
                                <span>{{$t('todo.Edit_todo_list')}}</span>
                              </div>
                            </div>
                          </CDropdownItem>
                          <CDropdownItem>
                            <div class="d-flex align-items-center" @click="showTodoList(todo.row.todo_list_id_external)">
                              <div class="post_action_icon text-center mr-1">
                                <i class="fas fa-list-ul"/>
                              </div>
                              <div class="flex-grow-1">
                                <span>{{$t('common.Show_details')}}</span>
                              </div>
                            </div>
                          </CDropdownItem>
                          <CDropdownItem>
                            <div class="d-flex align-items-center" @click="markAsComplete(todo.row.todo_list_id_external);" :disabled="todo.row.is_completed === true">
                              <div class="post_action_icon text-center mr-1">
                                <i class="fas fa-check"/>
                              </div>
                              <div class="flex-grow-1">
                                <span>{{$t('todo.Complete_todo_list')}}</span>
                              </div>
                            </div>
                          </CDropdownItem>                        
                        </CDropdown>
                      </div>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <div class="p-2 text-center">                    
                      <getStarted ref="getStarted" :content="$t('start.todo')" :showVideo="false" :youtubeId="'IKqV7DB8Iwg'"/>
                    </div>                
                  </template>              
                </b-table>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <b-modal class="todo" :can-cancel="[]" :active.sync="todoListModal" :width="960" scroll="keep">
      <CCard class="new_todo_list wizard mb-0 d-block">
        <CCardHeader class="pb-0">
          {{todoListModalData.todo_list_id === null || todoListModalData.todo_list_id === undefined ? $t('todo.New_todo_list') : $t('todo.Edit_todo_list')}}
        </CCardHeader>
        <CCardBody class="w-100">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <b-steps v-model="activeStep" size="is-small" class="wizard_steps h-100" :animated="isAnimated" :rounded="isRounded" :has-navigation="hasNavigation" :icon-prev="prevIcon" :icon-next="nextIcon" :label-position="labelPosition" :mobile-mode="mobileMode">                  
                <b-step-item v-if="todoListModalData.todo_list_id === null" :label="$t('todo.pick_a_template')" :clickable="isStepsClickable" class="p-0">                    
                  <div class="p-0 m-0 option_select_block">
                    <div class="options pr-3">
                      <div v-on:click="activeStep = activeStep + 1;" class="option">
                        <div class="option_name">
                          <span>{{ $t('todo.empty_list') }}</span>
                        </div>
                        <div class="text-right">                            
                          <i class="fas fa-chevron-right"/>
                        </div>              
                      </div>                
                      <div v-on:click="setTemplate(template);" v-for="template in myTemplates" v-bind:key="template.todo_list_template_id_external" class="option">
                        <div class="option_name">
                          <span>{{template.template_name}}</span>
                        </div>
                        <div class="text-right">                            
                          <i class="fas fa-chevron-right"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-step-item>

                <b-step-item :label="$t('todo.select_users')" :clickable="isStepsClickable">
                  <multiselect
                    class="data_table"
                    v-model="todoListModalData.users"
                    :options="teams"  
                    :multiple="true"
                    :group-select="true"
                    :close-on-select="false"
                    :hide-selected="true"                  
                    :placeholder="$t('common.Select_users')" 
                    :selectLabel="$t('common.Add_user')" 
                    :selectGroupLabel="$t('common.Add_team')" 
                    :selectedLabel="$t('Added')"
                    :deselectLabel="$t('common.Remove_user')"
                    :deselectGroupLabel="$t('common.Remove_team')"
                    group-values="users" 
                    group-label="team_name"                    
                    track-by="user_id_external" 
                    label="label">
                    <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                  </multiselect>
                </b-step-item>

                <b-step-item :label="$t('todo.check_todo_list')" :clickable="isStepsClickable">
                  <CRow>
                    <CCol cols="12" lg="12" class="pt-0 pb-0">
                      <CInput type="text" :label="$t('todo.your_name')" v-model="todoListModalData.name" required was-validated/>
                    </CCol>
                    <CCol cols="12" lg="12" class="pt-0 pb-0">                    
                      <p @click="descriptionCollapsed = !descriptionCollapsed" class="collapse_trigger mb-2">{{ $t('todo.open_to_add_description') }}</p>
                      <CCollapse :show="!descriptionCollapsed">
                        <CTextarea rows="4" :label="$t('todo.your_description')" v-model="todoListModalData.description"/>
                      </CCollapse>
                    </CCol>                    
                    <CCol cols="12" lg="12" class="pt-0 pb-0">
                      <div class="selected_option_badges">
                        <p class="mt-0 mb-2">{{ $t('todo.selected_users') }}</p>
                        <CBadge v-for="user in todoListModalData.users" v-bind:key="user.user_id_external">{{user.label}}</CBadge>
                      </div>
                    </CCol>
                    <CCol cols="12" lg="12" class="pb-0">
                      <b-switch class="mb-0 mt-1 mt-xl-0" v-model="todoListModalData.is_editable_by_users" size="is-small">{{ $t('todo.Editable_by_users') }}</b-switch>
                    </CCol>                    
                  </CRow>
                </b-step-item>

                <template v-if="customNavigation" slot="navigation" slot-scope="{previous, next}">
                  <div class="step_navigation p-0">
                    <b-button class="previous" :disabled="previous.disabled" @click.prevent="previous.action">{{ $t('wizard.previous_step') }}</b-button>
                    <b-button v-if="(activeStep > 0 || (todoListModalData.todo_list_id !== null && activeStep == 0)) && !next.disabled" class="next" :disabled="activeStep == 1 && todoListModalData.users.length == 0" @click.prevent="next.action">{{ $t('wizard.next_step') }}</b-button>
                  </div>
                </template>                       
              </b-steps>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div v-if="todoListModalData.todo_list_id === null || todoListModalData.todo_list_id === undefined">
            <CButton color="primary" @click="insertTodoList();" :disabled="activeStep !== 2 "><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
            <CButton color="secondary" @click="todoListModal = false; resetTodoListData(); getNewTodoListUUID(); activeStep = 0;"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </div>
          <div v-else>
            <CButton color="primary" @click="updateTodoList(todoListModalData.todo_list_id_external, todoListModalData);" :disabled="activeStep !== 1"><i class="fas fa-save mr-1"/>{{ $t('Update') }}</CButton>
            <CButton color="secondary" @click="todoListModal = false; activeStep = 0;"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </div>
        </CCardFooter>          
      </CCard>
    </b-modal>

  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import { v4 as uuidv4 } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import getStarted from '@/components/common/getStarted.vue';
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'Courses',
  components: {
    QuickEdit,
    Multiselect,
    getStarted,
    loadingSpinner
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      todoLists: [],
      overviewDataLoaded: false,
      categories: [],
      filter: { categories: [] },
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,
      searchEnabled: true,
      showCompleted: false,
      newTodoListExternalId: null,
      todoListModal: false,
      todoListModalData: {
        image: null,
        name: '',
        description: '',
        users: [],
        is_editable_by_users: true      
      },
      descriptionCollapsed: false,   
      myTemplates: [],
      teams: [],
      users: [],
      currentUser: {},
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: true,
      customNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',      
    }
  },
  methods: {
    pauseGetStarted() {
      if(this.todoLists.length === 0) {
        setTimeout(function(){
          this.$refs.getStarted.pauseVideo();
        }.bind(this), 100);
      } 
    },    
    getTodoLists () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/lists/overview')
      .then(res => {        
        this.todoLists = res.data.data;
        // Check if the pagination should be activated
        (this.todoLists.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
                
        this.overviewDataLoaded = true;

        // if(this.todoLists.length === 0) {
        //   setTimeout(function(){
        //     this.$refs.getStarted.playVideo();
        //   }.bind(this), 100);
        // }        

        if(!this.showCompleted) {
          this.todoLists = this.todoLists.filter( i => [false].includes( i.is_completed ) );
        }

        if(this.filter.categories.length > 0) {
          let selectedCategories = [];
          // Gather ID's of selected categories
          for(var c = 0; c < this.filter.categories.length; c++) {
            selectedCategories.push(this.filter.categories[c].todo_list_category_id);
          }
          // Return intents from selected categories
          this.todoLists = this.todoLists.filter(function(item) {
            return selectedCategories.includes(item.todo_list_category_id); 
          })
        }        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTodoListDetails (id) {
      let todoListIdExternal = id;

      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/list/' + todoListIdExternal + '/overview')
      .then(res => {
        let updatedTodoList = res.data.data;

        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.todoListTable !== undefined ) {

          let todoListIndex = this.$refs.todoListTable.data.findIndex(x => x.todo_list_id_external == updatedTodoList.todo_list_id_external);

          this.$refs.todoListTable.data[todoListIndex].name = updatedTodoList.name;
          this.$refs.todoListTable.data[todoListIndex].description = updatedTodoList.description;
          this.$refs.todoListTable.data[todoListIndex].todo_list_category_id = updatedTodoList.todo_list_category_id;
          this.$refs.todoListTable.data[todoListIndex].todo_list_category_name = updatedTodoList.todo_list_category_name;
          this.$refs.todoListTable.data[todoListIndex].nr_of_items = updatedTodoList.nr_of_items;
          this.$refs.todoListTable.data[todoListIndex].users = updatedTodoList.users;
        }        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    insertTodoList () {
      let params = {};
      params = this.todoListModalData;
      params.todo_list_id_external = this.newTodoListExternalId;
      (params.is_editable_by_users === true) ? params.editable_by_users = 'Y' : params.editable_by_users = 'N';

      let name = params.name; 

      if(name) {
        axios.post('v1/hub/todo/list', params)
        .then(res => {      
          // Get the todo lists
          this.getTodoLists();
          // Hide the modal
          this.todoListModal = false;
          // Reset the active wizard step
          this.activeStep = 0;
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.$buefy.toast.open({ message: this.$t('common.Empty_required_fields'), type: 'is-danger', duration: 2000 });
      }
    },         
    updateTodoList(todoListIdExternal, data) {
      let params = {}
      params = data;
      (params.is_editable_by_users === true) ? params.editable_by_users = 'Y' : params.editable_by_users = 'N';
              
      let name = params.name; 

      if(name) {              
        axios.put(process.env.VUE_APP_API_URL + '/v1/hub/todo/list/' + todoListIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('todo.Todo_list_updated'), type: 'is-success', duration: 3000 });
          // Update the todo list details
          this.getTodoListDetails(todoListIdExternal);
          // Hide the modal
          this.todoListModal = false;             
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.Empty_required_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    getTodoListCategories () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/categories')
      .then(res => {
        this.categories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    updateTodoListFilter() {
      // Save multiselect categories to localStorage
      localStorage.setItem('todoListFilter', JSON.stringify(this.filter.categories));
      // Gather the intents
      this.getTodoLists();
    },
    markAsComplete(todoListIdExternal) {
      axios.put('/v1/hub/todo/list/' + todoListIdExternal + '/complete')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('todo.Todo_list_completed'), type: 'is-success', duration: 3000 });
        this.getTodoLists();
      })
      .catch(err => {
        console.error(err); 
      }) 
    },
    showTodoList(todoListIdExternal) {
      this.$router.push({ path: `todo-lists/${todoListIdExternal.toString()}` });
    },
    setTemplate(template) {
      // Set the template name
      this.todoListModalData.template_name = template.template_name;
      // Set the template external id
      this.todoListModalData.todo_list_template_id_external = template.todo_list_template_id_external;
      // Set the name
      this.todoListModalData.name = template.template_name + ' ' + this.$moment(new Date()).format('DD-MM-YYYY');
      // Set active step of wizard
      this.activeStep = 1;
    },    
    getMyTemplates() {
      axios.get('v1/hub/todo/templates/my')
      .then(res => {
        this.myTemplates = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getNewTodoListUUID () {
      let params = {};
      params.uuid = uuidv4();

      axios.post('/v1/hub/todo/list/checkUUID', params)
      .then(res => {
        this.newTodoListExternalId = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      })  
    },
    getTeamMembers () {
      axios.get('/v1/hub/todo/members')
      .then(res => {
        let users = res.data.data;

        this.teams = [];
        // Loop through users
        var lookup = {};
        for (var user, index = 0; user = users[index++];) {
          var teamId = user.team_id;

          if (!(teamId in lookup)) {
            lookup[teamId] = 1;
            this.teams.push({
              team_id: user.team_id,
              team_name: user.team_name + ' (' + user.department_name + ')',
              users: []
            });
          }
        }

        // Loop through teams
        for(var t = 0; t < this.teams.length; t++) {
          // Loop through users
          for(var u = 0; u < users.length; u++) {
            // If a user is part of a team, push the user into the users array of this team          
            if(this.teams[t].team_id === users[u].team_id) {
              var index = this.teams.findIndex(i => i.team_id == this.teams[t].team_id);
              this.teams[index].users.push({
                user_id_external: users[u].user_id_external,
                value: users[u].user_id_external,
                name: users[u].name,
                label: users[u].name + ' (' + users[u].team_name + ' - ' + users[u].department_name + ')',
                text: users[u].name + ' (' + users[u].team_name + ' - ' + users[u].department_name + ')',
                team_id: users[u].team_id,
                team_name: users[u].team_name,
                department_id: users[u].department_id,
                department_name: users[u].department_name
              });        
            }
          }        
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getCurrentUser() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.currentUser = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });       
    },
    resetTodoListData () {
      this.todoListModalData = {
        todo_list_id: null,
        name: '',
        description: null,
        template_name: null,
        todo_list_template_id_external: null,
        image: null,
        users: [],
        is_editable_by_users: true
      }; 
      
      // Add the current user to the users array
      this.todoListModalData.users.push(this.currentUser);
    },            
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    // If available, set the filter according to localStorage data
    if(localStorage.getItem('todoListFilter') !== null && localStorage.getItem('todoListFilter') !== undefined) {
      this.filter.categories = JSON.parse(localStorage.getItem('todoListFilter')); 
    }
    // Reset data
    this.resetTodoListData();
    // Get the data
    this.getCurrentUser();
    this.getTodoLists();
    this.getTodoListCategories();
    this.getNewTodoListUUID();
    this.getTeamMembers();
    this.getMyTemplates();    
  }
}
</script>